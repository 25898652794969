<template>
  <div>
    <div class="selectDeck">
      <button v-on:click="selectOriginal()">Original</button>
      <button v-on:click="selectCustom()">Custom Deck</button>
    </div>
    <div id="deckPreview">
      <FullDeckPreview :deckName="deckName" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FullDeckPreview from "@/components/FullDeckPreview.vue";
// import { getDeck } from "@/services/ExampleService.js";

export default {
  name: "DeckViewer",
  components: {
    FullDeckPreview,
  },
  methods: {
    selectOriginal() {
      this.deckName = "Original";
      console.log("Select: Original");
    },
    selectCustom() {
      this.deckName = "Custom Deck";
      console.log("Select: Custom Deck");
    },
  },
  data() {
    return {
      deckName: "Original",
    };
  },
  mounted() {
    // getDeck("original")
    //   .then((theData) => {
    //     this.deck = theData;
    //     console.log(this.deck);
    //   })
    //   .catch((err) => {
    //     console.log("Oh noes: ", err);
    //   });
  },
};
</script>

<style scoped>
.fluidBox {
  display: flex;
  background-color: grey;
  margin-bottom: 20px;
}
button {
  padding: 1rem;
  font-size: 1rem;
  background-color: white;
  margin: 0 1rem 2rem 1rem;
  border-radius: 3px;
  border: 1px solid black;
}
</style>
