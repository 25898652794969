<template>
  <div
    v-for="card in cat.cards"
    class="card"
    :class="card.dealt && 'dealt'"
    :key="card.id"
    :id="card.id"
    :ref="card.id"
    :style="{
      'background-color': cat.color1,
      color: cat.color2,
      order: card.dealOrder,
    }"
    @mousedown="dragMouseDown($event, card.id)"
  >
    <h1
      :style="{ 'background-color': cat.color2, color: cat.color1 }"
      draggable
    >
      {{ cat.name }}
    </h1>
    <img
      v-if="cat.svgURL"
      :src="cat.svgURL"
      alt="Decorative motif."
      style="max-height: 4rem; width: auto"
    />
    <h5>{{ card.text }}</h5>
    <!-- <p v-if="card.dealt">dealt</p> -->
  </div>
</template>

<script>
export default {
  name: "Card-Backup",
  props: {
    category: String,
    msg: String,
  },
  data() {
    return {
      imgURL:
        "https://dl.airtable.com/.attachments/866685e674289dc357a5f06c3611787b/ccae66d1/IIF__identification_icon.svg",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  border: 1px solid black;
  background-color: #eee;
  width: 100px;
  height: 150px;
  margin: 10px -20px 10px 10px;
  padding: 5px;
}
p {
  font-size: 14px;
}
h1 {
  font-size: 12px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
