<template>
  <div v-for="(cat, id) in deck" :key="id" class="fluidBox">
    <Card
      v-for="(card, id) in cat.cards"
      :category="cat.name"
      :msg="card.text"
      :key="id"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Card from "@/components/Card-backup.vue";
import { getDeck } from "@/services/ExampleService.js";

export default {
  name: "FullDeckPreview",
  props: {
    deckName: String,
  },
  watch: {
    deckName: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      this.fetchNewDeck();
    },
  },
  components: {
    Card,
  },
  methods: {
    fetchNewDeck() {
      getDeck(this.deckName)
        .then((theData) => {
          this.deck = theData;
          console.log(this.deck);
        })
        .catch((err) => {
          console.log("Oh noes: ", err);
        });
    },
  },
  data() {
    return {
      deck: {},
    };
  },
  mounted() {
    this.deck = {};
    getDeck(this.deckName)
      .then((theData) => {
        this.deck = theData;
        console.log(this.deck);
      })
      .catch((err) => {
        console.log("Oh noes: ", err);
      });
  },
};
</script>

<style scoped>
.fluidBox {
  display: flex;
  background-color: grey;
  margin-bottom: 20px;
}
</style>
